html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
}

#app {
  height: 100%;
  width: 100%;
}

.page-loader {
  width: 100%;
  height: 100%;
  opacity: 1;
  pointer-events: none;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.page-loader.active {
  opacity: 0;
  pointer-events: none;
}

.loader-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid #ffffff4d;
  border-top-color: #000;
  border-radius: 50%;
  animation: 1s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=index.bb0f9e3a.css.map */
